@charset "utf-8";
/* CSS Document */

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 330px) {
}

#top .inner_02{ width:1680px; margin:0 auto; position:static;}

@media screen and (max-width: 450px) {
#top .inner_02{ width:100%;}
}


#top{ width:100%; background:url(https://cdn.shopify.com/s/files/1/0719/8415/2872/files/img_bg.jpg); }
#top img{ width:100%; display:block; }


/* ++++++++++++++++++++ */
/* +++ banner_01 +++++++++ */
/* ++++++++++++++++++++ */

.banner_01{ margin:0 0 80px 0;}
.banner_01 li{ margin:0 0 40px 0;}

@media screen and (max-width: 480px) {
.banner_01{ width:95%; margin:0 auto 40px auto;}
.banner_01 li{ margin:0;}
}

/* ++++++++++++++++++++ */
/* +++ ranking_01 +++++++++ */
/* ++++++++++++++++++++ */

.ranking_01{ margin:0 0 80px 0;}
.ranking_01 div{ position:relative; }
.ranking_01 div a{ position:absolute;
	background:#fff;
	display:block;
	filter:alpha(style=0, opacity=0);
	-moz-opacity:0;
	opacity:0;
}
.ranking_01 .rank_1 a.link_01{
	width:47%; height:17%;
	top:77%; right:6%;
}
.ranking_01 .rank_2 a.link_01{
	width:47%; height:17%;
	top:77%; right:6%;
}
.ranking_01 .rank_3 a.link_01{
	width:47%; height:17%;
	top:72%; right:6%;
}


.ranking_01 .rank_1 a:hover,
.ranking_01 .rank_2 a:hover,
.ranking_01 .rank_3 a:hover{
	filter:alpha(style=0, opacity=20);
	-moz-opacity:0.2;
	opacity:0.2;
}

@media screen and (max-width: 480px) {
.ranking_01{ margin:0 0 40px 0;}
}
/* ++++++++++++++++++++ */
/* +++ t_category +++++++++ */
/* ++++++++++++++++++++ */

.t_category{ margin:0 0 80px 0;}
.t_category .list_01 li{ width:49%; margin:0 0 20px 0;}
.t_category .list_01 li:nth-child(odd){ float:left;}
.t_category .list_01 li:nth-child(even){ float:right;}

@media screen and (max-width: 480px) {
.t_category{ width:95%; margin:0 auto 40px auto;}
.t_category .list_01 li{ margin:0 0 0px 0;}
}

/* ++++++++++++++++++++ */
/* +++ event_area_01 +++++++++ */
/* ++++++++++++++++++++ */

.event_area_01{
	width:100%;
	position:relative;
	background:url(https://cdn.shopify.com/s/files/1/0719/8415/2872/files/bg.jpg) no-repeat;
	background-size:100%;
}

.event_area_01 .t_event_area_01{
	position:absolute;
	top:48%;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}


/* ++++++++++++++++++++ */
/* +++ t_company_01 +++++++++ */
/* ++++++++++++++++++++ */

.t_company_01{
	position:relative;
	background:url(https://cdn.shopify.com/s/files/1/0719/8415/2872/files/img_t_company_bg.jpg);
	margin:200px 0 0 0;
}

.t_company_01 .inner_02 img.img_01{
	width:auto!important;
	position:absolute;
	top:-145px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}


@media screen and (max-width: 480px) {
.t_company_01{	margin:0 0 30px 0; }
.t_company_01 .inner_02 img.img_01{
	width:100%!important;
	top:0px;
}
}


/* ++++++++++++++++++++ */
/* +++ top_00 +++++++++ */
/* ++++++++++++++++++++ */





/* ++++++++++++++++++++ */
/* +++ top_01 +++++++++ */
/* ++++++++++++++++++++ */

.top_01{ margin:50px 0;}
.top_01 ul{ width:100%;}
.top_01 li{ width:33%; min-height:390px; border-right:#333 1px solid;}
.top_01 li:nth-child(3){ border-right:none;}

.top_01 li img{ width:91%; display:block; margin:0 auto;}
.top_01 li p{ width:80%; margin:0 auto; padding:0; line-height:24px;}
.top_01 li p.p_name{ padding:0 0 20px 0;}
.top_01 li p.price{ text-align:center;}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
.top_01 li{ width:33%; min-height:280px;}
}
@media screen and (max-width: 450px) {
.top_01 li{ width:100%; min-height:inherit; border-right:none; border-bottom:#ccc 1px solid; padding:0 0 30px 0; margin:0 0 30px 0; }
.top_01 li:nth-child(3){ border:none;}
.top_01 li p.p_name{ text-align:center;}

}
@media screen and (max-width: 330px) {
}















